import React from "react";

import { OMNIS_OBNOVENI_ODBERU_SLUG } from "../../constants";
import OmnisLP from "../../components/OmnisLP";
import { OmnisData } from "../../content/OmnisData";

const ObnoveniOdberu = () => {
  return <OmnisLP contentData={OmnisData[OMNIS_OBNOVENI_ODBERU_SLUG]} articles={[]} />;
};

export default ObnoveniOdberu;
